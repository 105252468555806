import React, { useEffect } from 'react'
import Router from 'next/router'
import Head from 'next/head'

function Home() {
  useEffect(() => {
    const { pathname } = Router
    if (pathname === '/') {
      window.location = 'https://bunch.ai'
    }
  })
  return (
    <div className="container">
      <Head>
        <title>Bunch</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
    </div>
  )
}

export default Home
